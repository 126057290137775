function initHeroPager() {
  document.querySelectorAll('[data-hero-pager]').forEach((heroPager) => {
    let maxImageDivHeight = 320;
    const bigImageContainer = heroPager.querySelector('[data-image-container]');
    const carouselImages = bigImageContainer.querySelectorAll('[data-image-index]');
    const carouselContents = bigImageContainer.querySelectorAll('[data-image-index] .view-hero');
    const thumbnailArea = heroPager.querySelector('[data-thumbnail-navigation]');
    const thumbnails = thumbnailArea.querySelectorAll('button[data-thumbnail-index]');
    const imageCount = carouselImages.length;

    const upButton = heroPager.querySelector('[data-navigation-btn="-1"]');
    const downButton = heroPager.querySelector('[data-navigation-btn="1"]');

    // Start at top
    scrollToIndex(0);

    // remove thumbnail and prev/next buttons when only one item
    if (imageCount < 2) {
      thumbnailArea.classList.add('d-none');
    }

    // adjust scrollable area height to the maximum card height
    carouselImages.forEach((item) => {
      const imageDivHeight = Math.round(item.offsetHeight);
      maxImageDivHeight = Math.max(imageDivHeight, maxImageDivHeight);
      item.addEventListener('focusin', () => {
        scrollToIndex(Number(item.dataset.imageIndex));
      });
    });

    carouselContents.forEach((item) => (item.style.height = `${maxImageDivHeight}px`));
    bigImageContainer.style.height = `${maxImageDivHeight}px`;

    upButton.onclick = handleArrowClick;
    thumbnails.forEach((thumbnail) => (thumbnail.onclick = handleThumbnailClick));
    downButton.onclick = handleArrowClick;

    function handleArrowClick(e) {
      const { currentTarget } = e;
      const direction = Number(currentTarget.dataset.navigationBtn);
      let currentIndex = Number(heroPager.querySelector('[data-active-image]').dataset.imageIndex);

      currentIndex += direction;
      currentIndex = Math.min(Math.max(0, currentIndex), imageCount - 1);

      scrollToIndex(currentIndex);
    }

    function handleThumbnailClick(e) {
      const { currentTarget } = e;
      const imgIndexNumber = Number(currentTarget.dataset.thumbnailIndex);
      scrollToIndex(imgIndexNumber);
    }

    function scrollToIndex(index) {
      // Set slide and scroll
      carouselImages.forEach((image) => {
        const imageIndex = Number(image.dataset.imageIndex);
        const video = image.querySelector('video');
        if (imageIndex === index) {
          bigImageContainer.scrollTo({ top: image.offsetTop, left: image.offsetLeft, behavior: 'smooth' });
          image.classList.add('active');
          if (video) {
            console.log('play');
            video.play();
            video.dataset.paused = null;
          }
          image.setAttribute('data-active-image', '');
        } else {
          image.classList.remove('pause');
          if (video) {
            console.log('pause');
            video.pause();
            video.dataset.paused = '1';
          }
          image.removeAttribute('data-active-image');
        }
      })

      // Set thumbnail classes
      const thumbsToShow = index === 0 ? [0, 1, 2] // beginning
        : index === imageCount - 1 ? [imageCount - 3, imageCount - 2, imageCount - 1] // end
          : [index - 1, index, index + 1];

      thumbnails.forEach((thumbnail) => {
          const thumbnailIndex = Number(thumbnail.dataset.thumbnailIndex);
          if (thumbsToShow.includes(thumbnailIndex)) {
            thumbnail.classList.add('show')
          } else {
            thumbnail.classList.remove('show')
          }
          if (thumbnailIndex === index) {
            thumbnail.classList.add('active');
            thumbnail.setAttribute('data-active-thumbnail-image', '');
          } else {
            thumbnail.classList.remove('active');
            thumbnail.removeAttribute('data-active-thumbnail-image');
          }
        }
      );

      // disable prev/next buttons based on index
      if (index === 0) {
        upButton.classList.add('disabled');
        downButton.classList.remove('disabled');
      } else if (index === imageCount - 1) {
        upButton.classList.remove('disabled');
        downButton.classList.add('disabled');
      } else {
        downButton.classList.remove('disabled');
        upButton.classList.remove('disabled');
      }
    }
  });
}

function resetHeroPager() {
  // Remove heights and reinitialize.
  document.querySelectorAll('[data-hero-pager]').forEach((heroPager) => {
    const bigImageContainer = heroPager.querySelector('[data-image-container]');
    const carouselContents = bigImageContainer.querySelectorAll('[data-image-index] .view-hero');
    carouselContents.forEach((item) => (item.style.height = null));
    bigImageContainer.style.height = null;
  });
  // window.setTimeout(initHeroPager);
  initHeroPager();
}

document.addEventListener('DOMContentLoaded', initHeroPager);

window.addEventListener('resize', resetHeroPager);
